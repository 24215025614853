import React from 'react';
import './Quotes.css';

function Quotes() {
    return (
        <div>
            <p className="quotes">[ build, don't talk ]</p>
        </div>
    );
}

export default Quotes;